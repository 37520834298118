import { ReactNode, useCallback, useEffect, useRef, MouseEvent } from 'react';
import clsx from 'clsx';
import { motion, Transition, Variant } from 'framer-motion';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Overlay from '../Overlay/Overlay';
import { cva } from 'class-variance-authority';

declare global {
  interface Window {
    // количество одновременно открытых попапов
    _popupsCount: number;
  }
}

export interface IPopupProps {
  className?: string;
  scrollClassName?: string;
  wrapperClassName?: string;
  children: ReactNode;
  animations?: {
    overlay?: PopupAnimation;
    scroll?: PopupAnimation;
    wrapper?: PopupAnimation;
  };
  onClose?: () => void;
}

export const Popup = ({
  children,
  className,
  wrapperClassName,
  scrollClassName,
  animations,
  onClose,
}: IPopupProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current) {
      const scrollEl = scrollRef.current;
      window._popupsCount = window._popupsCount ? window._popupsCount + 1 : 1;

      disableBodyScroll(scrollEl as HTMLElement, {
        reserveScrollBarGap: true,
      });

      return () => {
        window._popupsCount = window._popupsCount ? window._popupsCount - 1 : 0;

        if (!window._popupsCount) {
          enableBodyScroll(scrollEl);
        }
      };
    }
  }, []);

  const onScrollClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (
        onClose &&
        e.target !== wrapperRef.current &&
        !wrapperRef.current?.contains(e.target as Node)
      ) {
        onClose?.();
      }
    },
    [onClose]
  );

  return (
    <div className={clsx(cvaRoot(), className)}>
      <Overlay animation={getAnimationProps(animations?.overlay)} />

      <motion.div
        ref={scrollRef}
        className={clsx(cvaScroll(), scrollClassName)}
        {...getAnimationProps(animations?.scroll)}
        onClick={onScrollClick}>
        <motion.div
          ref={wrapperRef}
          className={clsx(cvaWrapper(), wrapperClassName)}
          {...getAnimationProps(animations?.wrapper)}>
          {children}
        </motion.div>
      </motion.div>
    </div>
  );
};

export interface PopupAnimation {
  variants: {
    open: Variant;
    collapsed: Variant;
  };
  transition: Transition;
}

const getAnimationProps = (animation?: PopupAnimation) => ({
  initial: 'collapsed' as const,
  animate: 'open' as const,
  exit: 'collapsed' as const,
  variants: animation?.variants,
  transition: animation?.transition,
});

const cvaRoot = cva(['Popup-cvaRoot', 'fixed inset-0 z-[100]']);

const cvaScroll = cva([
  'Popup-cvaScroll',
  'fixed top-0 left-0 z-[2]',
  'w-full h-full overflow-auto',
  'text-center',
  'before:inline-block before:w-0 before:h-full before:align-middle',
]);

const cvaWrapper = cva([
  'Popup-cvaWrapper',
  'relative z-[2]',
  'inline-block',
  'text-left align-middle',
  'pointer-events-auto',
]);
