import { ILocalesConfig } from '@/shared/api/locales/getLocalesConfig/types';

export const getLocalesConfig = async () => {
  /* TODO: в будущем загружать список локалей и локаль по умолчанию с бэка */
  return new Promise<ILocalesConfig>((resolve) => {
    resolve({
      defaultLocale: 'ru',
      locales: [
        {
          code: 'ru',
          label: 'Русский',
        },
        {
          code: 'en',
          label: 'English',
        },
      ],
    });
  });
};
