import { FC, ReactNode } from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import PopupPortal from '../PortalPopup/PortalPopup';
import { Heading } from '@/shared/ui/Heading/Heading';
import Button from '@/shared/ui/Button/Button';
import { IPortalPopupProps } from '@/entities/PortalPopup/PortalPopup';
import clsx from 'clsx';
import CloseIcon from '~/icons/close-white.svg';
import WrapperIcon from '@/shared/ui/WrapperIcon/WrapperIcon';
import { Transition } from 'framer-motion';
import { PopupAnimation } from '@/shared/ui/Popup/Popup';

type IPortalPopupPropsWithoutChildren = Omit<
  IPortalPopupProps,
  'children' | 'animations'
>;

interface Props
  extends IPortalPopupPropsWithoutChildren,
    VariantProps<typeof cvaInner> {
  innerClassName?: string;
  titleClassName?: string;
  title?: string;
  withoutCancel?: boolean;
  button?: string;
  cancelButton?: string;
  isLoading?: boolean;
  disabled?: boolean;
  href?: string;
  withClose?: boolean;
  children?: ReactNode;
  onClick?: () => void;
}

const DefaultPopup: FC<Props> = ({
  className,
  wrapperClassName,
  scrollClassName,
  button,
  cancelButton,
  title,
  withoutCancel,
  onClick,
  children,
  innerClassName,
  titleClassName,
  withClose,
  href,
  isLoading,
  disabled,
  onClose,
  gapItems,
  ...props
}) => {
  return (
    <PopupPortal
      className={clsx(cvaRoot(), className)}
      scrollClassName={clsx(cvaScroll(), scrollClassName)}
      wrapperClassName={clsx(cvaWrapper(), wrapperClassName)}
      animations={{
        wrapper: { variants: wrapperVariants, transition },
        overlay: { variants: overlayVariants, transition },
      }}
      onClose={onClose}
      {...props}>
      <div className={clsx(cvaInner({ gapItems }), innerClassName)}>
        {title && (
          <Heading
            className={clsx(cvaTitle({ withClose }), titleClassName)}
            size={'h3'}>
            {title}
          </Heading>
        )}
        {children}
        {(button || !withoutCancel) && (
          <div className={cvaButtons()}>
            {button && (
              <Button
                theme={'primary'}
                href={href}
                onClick={onClick}
                size={'sm'}
                isLoading={isLoading}
                disabled={disabled}
                stretched>
                {button}
              </Button>
            )}
            {!withoutCancel && (
              <Button theme={'gray'} onClick={onClose} size={'sm'} stretched>
                {cancelButton}
              </Button>
            )}
          </div>
        )}
        {withClose && (
          <WrapperIcon className={cvaClose()} onClick={onClose} theme={'black'}>
            <CloseIcon />
          </WrapperIcon>
        )}
      </div>
    </PopupPortal>
  );
};

const transition: Transition = { duration: 0.6, ease: 'easeOut' };

const overlayVariants: PopupAnimation['variants'] = {
  open: {
    opacity: 1,
  },
  collapsed: {
    opacity: 0,
  },
};

const wrapperVariants: PopupAnimation['variants'] = {
  open: {
    opacity: 1,
    translateY: '0%',
  },
  collapsed: {
    opacity: 0,
    translateY: '100%',
  },
};

const cvaRoot = cva(['DefaultPopup-cvaRoot']);

const cvaScroll = cva(['DefaultPopup-cvaScroll', 'p-3.2 sm-max:p-2']);

const cvaClose = cva([
  'DefaultPopup-cvaClose',
  '!absolute top-1.6 right-1.6 md-max:top-0.8 md-max:right-0.8',
]);

const cvaInner = cva(
  [
    'DefaultPopup-cvaInner',
    'px-3.2 py-2.4 ',
    'bg-cBlack rounded-[2.4rem] md-max:rounded-[1.6rem]',
    'flex flex-col',
  ],
  {
    variants: {
      gapItems: {
        lg: 'gap-4.8 md-max:gap-2.4',
        md: 'gap-2.4',
        sm: 'gap-1.6',
      },
    },
  }
);

const cvaTitle = cva(['DefaultPopup-cvaTitle'], {
  variants: {
    withClose: {
      true: 'max-w-[calc(100%-4.8rem)] md-max:max-w-[calc(100%-3.2rem)]',
      false: '',
    },
  },
});

const cvaWrapper = cva([
  'DefaultPopup-cvaWrapper',
  'w-[42.8rem] sm-max:w-full',
]);

const cvaButtons = cva([
  'DefaultPopup-cvaButtons',
  'flex flex-col gap-1.6 md-max:gap-1.2',
  'w-full',
]);

export default DefaultPopup;
