import { forwardRef, ReactNode } from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { ButtonProps } from 'react-html-props';
import FigureIcon from '~/icons/contest-bg.svg';
import clsx from 'clsx';
import { LocalizedLink } from '@/shared/i18n/routing';

interface Props extends ButtonProps, VariantProps<typeof cvaRoot> {
  isStatic?: boolean;
  children: ReactNode;
  href?: string;
  variant?: 'circle' | 'figure';
}

const cvaRoot = cva(
  [
    'WrapperIcon-cvaRoot',
    'rounded-[50%]',
    'font-extrabold text-1.8',
    'w-4.8 h-4.8 flex-shrink-0',
    'flex justify-between items-center',
    '[&>svg]:duration-300 duration-300',
  ],
  {
    variants: {
      theme: {
        primary: 'bg-cPurple700 [&>svg]:fill-cPurple700',
        focus: 'bg-cPurple1100 [&>svg]:fill-cPurple500',
        inverse: 'bg-white [&>svg]:fill-white',
        active: 'bg-cPurple1100 [&>svg]:fill-cPurple1100',
        gray: 'bg-cGray900 [&>svg]:fill-cGray900',
        black: 'bg-black [&>svg]:fill-black',
        blurred: 'bg-cBlurred [&>svg]:fill-cBlurred',
      },
      isFigure: {
        true: '!bg-transparent',
      },
    },
    defaultVariants: {
      theme: 'gray',
    },
  }
);

const cvaSvg = cva(
  [
    'WrapperIcon-cvaSvg',
    '!w-full',
    'flex justify-center relative ',
    'font-extrabold text-1.8',
    '[&>svg]:w-2.4 [&>svg]:absolute-center',
    '[&>svg]:duration-300 duration-300',
  ],
  {
    variants: {
      theme: {
        primary: '[&>svg]:fill-white text-white ',
        focus: '[&>svg]:fill-cPurple500 text-cPurple500',
        inverse: '[&>svg]:fill-cPurple700 text-cPurple700 ',
        active: '[&>svg]:fill-cPurple500 text-cPurple500 ',
        gray: '[&>svg]:fill-white text-white ',
        black: '[&>svg]:fill-white text-white ',
        blurred: '[&>svg]:fill-white text-white ',
      },
    },
    defaultVariants: {
      theme: 'gray',
    },
  }
);

const cvaFigure = cva(['WrapperIcon-cvaVariant', 'absolute w-4.8 h-4.8']);

const WrapperIcon = forwardRef<HTMLDivElement, Props>(
  (
    {
      isStatic,
      className,
      href,
      theme,
      children,
      variant = 'circle',
      ...props
    },
    ref
  ) => {
    return isStatic ? (
      <div
        ref={ref as any}
        className={clsx(
          cvaRoot({ theme, isFigure: variant === 'figure' }),
          className
        )}
        {...(props as any)}>
        {variant === 'figure' && <FigureIcon className={cvaFigure()} />}{' '}
        <span className={cvaSvg({ theme })}> {children}</span>
      </div>
    ) : href ? (
      <LocalizedLink
        ref={ref as any}
        href={href}
        className={clsx(
          cvaRoot({ theme, isFigure: variant === 'figure' }),
          className
        )}
        {...(props as any)}>
        {variant === 'figure' && <FigureIcon className={cvaFigure()} />}
        <span className={cvaSvg({ theme })}> {children}</span>
      </LocalizedLink>
    ) : (
      <button
        ref={ref as any}
        className={clsx(
          cvaRoot({ theme, isFigure: variant === 'figure' }),
          className
        )}
        {...(props as any)}>
        {variant === 'figure' && <FigureIcon className={cvaFigure()} />}
        <span className={cvaSvg({ theme })}> {children}</span>
      </button>
    );
  }
);

WrapperIcon.displayName = 'WrapperIcon';
export default WrapperIcon;
