import { QueryClient, useQuery } from '@tanstack/react-query';
import { getLocalesConfig } from '@/shared/api/locales/getLocalesConfig';

export const useGetLocalesConfig = () => {
  return useQuery({
    queryKey: getUseGetLocalesConfigQueryKey(),
    queryFn: getLocalesConfig,
    staleTime: Infinity,
  });
};

export const prefetchGetLocalesConfig = async (queryClient: QueryClient) => {
  await queryClient.prefetchQuery({
    queryKey: getUseGetLocalesConfigQueryKey(),
    queryFn: getLocalesConfig,
  });
};

export const getUseGetLocalesConfigQueryKey = () => ['getLocalesConfig'];
