import { FC } from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { motion, Variant, Transition } from 'framer-motion';
import clsx from 'clsx';

interface Props extends VariantProps<typeof cvaRoot> {
  onClick?: () => void;
  className?: string;
  animation?: Animation;
}

interface Animation {
  variants?: {
    open?: Variant;
    collapsed?: Variant;
  };
  transition?: Transition;
}

const Overlay: FC<Props> = ({
  position = 'fixed',
  onClick,
  className,
  animation,
}) => {
  return (
    <motion.div
      className={clsx(cvaRoot({ position }), className)}
      initial={'collapsed'}
      animate={'open'}
      exit={'collapsed'}
      variants={animation?.variants}
      transition={animation?.transition}
      onClick={onClick}
    />
  );
};

const cvaRoot = cva(['Overlay-cvaRoot', 'z-[1] backdrop-blur-[5rem] '], {
  variants: {
    position: {
      fixed: 'fixed left-0 bottom-0 top-0 right-0',
      absolute: 'absolute left-0 bottom-0 top-0 right-0',
    },
  },
});

export default Overlay;
