import { API } from '../../api';
import { axiosInstance } from '../../axios';
import { IGetCurrentUserResponse } from '@/shared/api/user/getMe/types';

interface Params {
  lang: string;
}

export const getMe = async (params: Params) => {
  const result = await axiosInstance.get<IGetCurrentUserResponse>(API.getMe, {
    params,
  });
  return result.data;
};
