import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { IPopupProps, Popup } from '@/shared/ui/Popup/Popup';
import { Portal } from '@/shared/ui/Portal/Portal';

export type IPortalPopupProps = {
  isOpen?: boolean;
} & IPopupProps;

export default function PopupPortal({
  children,
  isOpen,
  ...props
}: IPortalPopupProps) {
  return (
    <AnimatePresence>
      {isOpen && (
        <Portal>
          <Popup {...props}>{children}</Popup>
        </Portal>
      )}
    </AnimatePresence>
  );
}
