'use client';
import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { useLocalStorage } from 'react-use';

interface IAuthContext {
  isPopupOpen: boolean;
  openPopup: () => void;
  closePopup: () => void;
  token: string | undefined;
  setToken: Dispatch<SetStateAction<string | undefined>>;
  codeVerifier: string | undefined;
  setCodeVerifier: Dispatch<SetStateAction<string | undefined>>;
  redirectUrl: string | undefined;
  setRedirectUrl: Dispatch<SetStateAction<string | undefined>>;
}

const AuthContext = createContext<IAuthContext | undefined>(undefined);

export const useAuth = (): IAuthContext => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};

interface PopupProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: PopupProviderProps) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = useCallback(() => {
    setIsPopupOpen(true);
  }, []);

  const closePopup = useCallback(() => {
    setIsPopupOpen(false);
  }, []);

  useEffect(() => {
    (window as any).openAuthPopup = openPopup;

    return () => {
      delete (window as any).openAuthPopup;
    };
  }, [openPopup]);

  const [token, setToken] = useLocalStorage<string>('token');
  const [codeVerifier, setCodeVerifier] =
    useLocalStorage<string>('code_verifier');
  const [redirectUrl, setRedirectUrl] = useLocalStorage<string>('redirect_url');

  return (
    <AuthContext.Provider
      value={{
        isPopupOpen,
        openPopup,
        closePopup,
        token,
        setToken,
        codeVerifier,
        setCodeVerifier,
        redirectUrl,
        setRedirectUrl,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
