import { useQuery } from '@tanstack/react-query';
import { getMe } from '../api/user/getMe';
import { useAuth } from '@/shared/providers/AuthProvider';
import { isAxiosError } from 'axios';

type Params = Parameters<typeof getMe>[0];

interface Options {
  enabled?: boolean;
}

const useGetMe = (params: Params, options?: Options) => {
  const { token, setToken } = useAuth();

  const response = useQuery({
    queryFn: () => getMe(params),
    queryKey: getUseGetMeQueryKey(params),
    enabled:
      Boolean(token) &&
      (options?.enabled !== undefined ? options.enabled : true),
    retry: (_, error) => {
      if (
        isAxiosError(error) &&
        error.response?.data?.data === 'Требуется авторизация'
      ) {
        setToken('');
        return false;
      } else {
        return true;
      }
    },
    meta: {
      skipGlobalErrorHandler: true,
    },
  });

  return { ...response, isAuth: Boolean(response?.data?.result?.data?.ID) };
};

export const getUseGetMeQueryKey = (params: Params) => ['getMe', params];

export default useGetMe;
